import {Canvas, extend, useFrame, useThree} from "@react-three/fiber"
import {
    CameraControls,
    Center,
    ContactShadows,
    Environment,
    Float,
    Grid,
    Lightformer, Loader,
    MeshTransmissionMaterial, PerformanceMonitor,
    Stats,
    Text3D,
    useFBO,
    useGLTF
} from "@react-three/drei"
import {EffectComposer, N8AO, TiltShift2} from "@react-three/postprocessing"
import {Route} from "wouter"
import {useEffect, useRef, useState} from "react";

useGLTF.preload("/3d/logo.glb")
useGLTF.preload("/3d/chair.glb")
useGLTF.preload("/3d/rad.glb")
useGLTF.preload("/3d/monitor.3.glb")
useGLTF.preload("/3d/gpu.glb")

export default function App() {
    const version = process.env.REACT_APP_VERSION
    const commit = process.env.REACT_APP_COMMIT
    const showStats = !(process.env.REACT_APP_PRODUCTION_BUILD === 'true');

    return (
        <>
            <div className="canvas-container">
                <Canvas eventSource={document.getElementById("root")} eventPrefix="client" shadows
                        camera={{position: [0, 0, 2], fov: 45}}>
                    <CameraAnimation/>
                    {/*camera={{position: [0, 0, 20], fov: 65}}>*/}
                    <color attach="background" args={["#e0e0e0"]}/>
                    <spotLight position={[20, 20, 10]} penumbra={1} castShadow angle={0.2}/>

                    <Route path="/">
                        <Logo scale={85} position={[0, 0, 0]}/>
                        <OrbitingModel orbitCenter={[0, 0, 0]} orbitRadius={8} speed={0.4}>
                            <Float floatIntensity={0} rotationIntensity={5} speed={3}>
                                <Rad scale={150} rotation={[0, Math.PI, 0]}/>
                            </Float>
                        </OrbitingModel>
                        <OrbitingModel orbitCenter={[0, 0, 0]} orbitRadius={13} speed={0.1} tiltAngle={Math.PI / 4}>
                            <Float floatIntensity={0} rotationIntensity={5} speed={3}>
                                <Monitor scale={250} rotation={[0, 0, 0]}/>
                            </Float>
                        </OrbitingModel>
                        <OrbitingModel orbitCenter={[0, 0, 0]} orbitRadius={10} speed={0.2} tiltAngle={Math.PI / 3 * 2}>
                            <Float floatIntensity={0} rotationIntensity={5} speed={3}>
                                <Chair scale={200} rotation={[0, 0, 0]}/>
                            </Float>
                        </OrbitingModel>
                        {/*<OrbitingModel orbitCenter={[0, 0, 0]} orbitRadius={10} speed={0.2} tiltAngle={Math.PI/2}>
                            <Float floatIntensity={0} rotationIntensity={5} speed={3}>
                                <Gpu scale={10} rotation={[0, 0, 0]} />
                            </Float>
                        </OrbitingModel>*/}
                        <ComingSoon font={"fonts/Poppins_Bold_limited.json"}
                                    curveSegments={12}
                                    bevelEnabled
                                    bevelSize={0.03}
                                    bevelThickness={0.2}
                                    height={2}
                                    letterSpacing={-0.06}
                                    size={4}/>
                    </Route>
                    <ContactShadows scale={100} position={[0, -10, 0]} blur={1} far={100} opacity={0.5}/>
                    <Environment preset="city">
                        <Lightformer intensity={8} position={[10, 5, 0]} scale={[10, 50, 1]}
                                     onUpdate={(self) => self.lookAt(0, 0, 0)}/>
                    </Environment>
                    <EffectComposer disableNormalPass>
                        <N8AO aoRadius={1} intensity={1}/>
                        <TiltShift2 blur={0.5}/>
                    </EffectComposer>
                    <Grid position={[0, -10, 0]} args={[10.5, 10.5]} cellSize={1} cellThickness={1}
                          cellColor={'#a4a4a4'}
                          sectionSize={4} sectionThickness={1.8} sectionColor={'#000000'} fadeStrength={1}
                          infiniteGrid={true}/>
                    {/*<CameraControls />*/}
                </Canvas>
            </div>
            {showStats && <Stats/>}
            <Loader/>
            <div className="version d-flex align-items-center justify-content-center flex-column pb-3">
                <small>Ozan Yolyapar</small>
                <small>2012 - {new Date().getFullYear()} © All rights reserved.</small>
                <small>v{version} {commit}</small>
            </div>
        </>
    )
}

extend({CameraControls});

function easeOutCubic(t) {
    return 1 - Math.pow(1 - t, 3);
}

function easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
}

function CameraAnimation() {
    const {camera} = useThree();

    useEffect(() => {
        const startZ = 2; // Start position on Z axis
        const endZ = 40; // End position on Z axis
        const duration = 7000; // Duration of the animation in milliseconds (10 seconds)
        const delay = 3000; // Delay before the animation starts in milliseconds (5 seconds)
        let startTime = null;

        const animate = (time) => {
            if (!startTime) {
                startTime = time;
            }
            const elapsed = time - startTime;
            const fraction = Math.min(elapsed / duration, 1);
            const easedFraction = easeInOutCubic(fraction);
            camera.position.z = startZ + (endZ - startZ) * easedFraction;

            if (easedFraction < 1) {
                requestAnimationFrame(animate);
            }
        };

        const timer = setTimeout(() => {
            requestAnimationFrame(animate);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [camera]); // Dependencies array includes camera to ensure reference stability

    return null; // This component does not render any visual elements
}

function Rad(props) {
    const groupRef = useRef()
    const {nodes} = useGLTF('3d/rad.glb')

    return (
        <group ref={groupRef} {...props} dispose={null}>
            <primitive object={nodes.Scene}/>
        </group>
    )
}

function Chair(props) {
    const groupRef = useRef()
    const {nodes} = useGLTF('3d/chair.glb')

    return (
        <group ref={groupRef} {...props} dispose={null}>
            <primitive object={nodes.Scene}/>
        </group>
    )
}

function Monitor(props) {
    const groupRef = useRef()
    const {nodes} = useGLTF('3d/monitor.3.glb')

    return (
        <group ref={groupRef} {...props} dispose={null}>
            <primitive object={nodes.Scene}/>
        </group>
    )
}

function Gpu(props) {
    const groupRef = useRef()
    const {nodes} = useGLTF('3d/gpu.glb')

    return (
        <group ref={groupRef} {...props} dispose={null}>
            <primitive object={nodes.Scene}/>
        </group>
    )
}

function ComingSoon(textOptions) {
    let zAxis = -10;
    let yAxis = 3;
    const [matSamples, setMatSamples] = useState(1)

    let mat = {
        color: "#000000",
        roughness: 0.4,
        reflectivity: 0.6,
        samples: matSamples
    }

    return (
        <>
            <PerformanceMonitor factor={0}
                                onChange={({factor}) => {
                                    let sampl_e = Math.floor(1 + 15 * factor)
                                    setMatSamples(sampl_e);
                                    // console.log("factor", factor, "samples", sampl_e);
                                }}/>
            <Center position={[0, yAxis, zAxis]}>
                <Text3D {...textOptions}>
                    COMING
                    <meshPhysicalMaterial {...mat}/>
                </Text3D>
            </Center>
            <Center position={[0, -yAxis, zAxis]}>
                <Text3D {...textOptions}>
                    SOON
                    <meshPhysicalMaterial {...mat}/>
                </Text3D>
            </Center>
        </>
    )
}

function OrbitingModel({children, orbitCenter, orbitRadius, speed, tiltAngle = 0}) {
    const ref = useRef();

    // Corrected orbit logic for accurate tilting between x-z and y-z planes
    useFrame(({clock}) => {
        const elapsedTime = clock.getElapsedTime();
        const angle = elapsedTime * speed;  // Calculate the angle for the orbit

        // Correctly computing positions based on tilt
        const x = orbitCenter[0] + Math.cos(angle) * orbitRadius * Math.cos(tiltAngle);
        const z = orbitCenter[2] + Math.sin(angle) * orbitRadius;
        const y = orbitCenter[1] + Math.cos(angle) * orbitRadius * Math.sin(tiltAngle);

        ref.current.position.set(x, y, z);
    });

    return (
        <group ref={ref}>
            {children}
        </group>
    );
}

function Suzi(props, materialColor = "#9d4b4b") {
    const {nodes} = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/suzanne-high-poly/model.gltf')
    const modelRef = useRef();

    return (
        <mesh castShadow receiveShadow geometry={nodes.Suzanne.geometry} ref={modelRef} {...props}>
            <meshStandardMaterial color={materialColor}/>
        </mesh>
    )
}

function Logo(props) {
    let mat = {
        transmission: 1,
        thickness: 5,
        chromaticAberration: 0.03,
        anisotropicBlur: 0.1,
        transmissionSampler: false,
        samples: 6,
        color: '#fff'
    }
    const {nodes} = useGLTF("/3d/logo.glb")
    const groupRef = useRef()
    const buffer = useFBO()
    useFrame((state) => {
        state.gl.setRenderTarget(buffer)
        state.gl.render(state.scene, state.camera)
        state.gl.setRenderTarget(null)
    })
    return (
        <group ref={groupRef} {...props} dispose={null}>
            <Float floatIntensity={0.03}>
                <mesh receiveShadow castShadow geometry={nodes.a_beveled.geometry} rotation={[0, -Math.PI / 2, 0]}
                      position={[0, 0.05, 0]}>
                    <MeshTransmissionMaterial {...mat} buffer={buffer.texture}/>
                </mesh>
            </Float>
            <Float floatIntensity={0.03}>
                <mesh receiveShadow castShadow geometry={nodes.z_beveled.geometry} rotation={[0, -Math.PI / 2, 0]}>
                    <MeshTransmissionMaterial {...mat} buffer={buffer.texture}/>
                </mesh>
            </Float>
            <Float floatIntensity={0.03}>
                <mesh receiveShadow castShadow geometry={nodes.a_beveled.geometry}
                      rotation={[0, -(Math.PI / 2) * 3, Math.PI]}
                      position={[0, -0.05, 0]}>
                    <MeshTransmissionMaterial {...mat} buffer={buffer.texture}/>
                </mesh>
            </Float>
        </group>
    )
}
